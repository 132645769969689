var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4ywPvy7sxfAQwM63YohWM"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  // eslint-disable-next-line i18next/no-literal-string
  dsn: "https://1dc86b51d0701d5c9f156b9d15c9e43b@o4507271372406784.ingest.de.sentry.io/4507271918977104",
  // eslint-disable-next-line i18next/no-literal-string
  tunnel: "/api/sentry-tunnel",
  enableTracing: false,
  debug: false,
});
